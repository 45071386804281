export default {
  main: {
    heading: 'Make the world fun!',
    subheading: 'Do you really like the game?',
    description: 'A place to create games that',
    description2: 'will make your life a joyful one, CookApps',
    join_us: 'Would you like to join us?',
    life: {
      title: 'You can work like this with CookApps!',
      title2: 'Who goes well with CookApps?',
      title3: 'Why CookApps is more special?',
      work1: {
        1: 'Stop missed timing game dev!',
        2: 'Start developing the game',
        3: 'the market wants now'
      },
      work2: {
        1: 'Decisions throughout',
        2: 'game development are directly',
        3: 'controlled by each team'
      },
      work3: {
        1: 'Targeting overseas markets',
        2: 'and gain experience',
        3: 'casual game development'
      },
      work4: {
        1: 'Spraying system',
        2: 'that can grow your dream',
        3: 'as a founder'
      },
      fit1: {
        1: 'Who can decide their own',
        2: 'growth direction and goals'
      },
      fit2: {
        1: 'Who think about the fun & experience',
        2: 'the game from user\'s perspective'
      },
      fit3: {
        1: 'Who practice continuous learning',
        2: 'and application to the game market'
      },
      fit4: {
        1: 'Who grow through quick attempts,',
        2: 'complementation and development'
      },
      benefits1 : {
        1: 'Free to experiment, GameJam!',
        2: 'Once every 3 months, for 3 days'
      },
      benefits2 : {
        1: 'Study during business hours?!',
        2: 'Operating Community Of Practice'
      },
      benefits3 : {
        1: 'Honey-like growth support!',
        2: 'Support education & book costs'
      },
      benefits4 : {
        1: '2 hours for lunch and 35 hours per week',
        2: 'Efficient & productive working environment'
      },
      benefits5 : {
        1: 'Make game healthy!',
        2: 'Health checkup & massage room'
      },
      benefits6 : {
        1: 'Refreshing surely!',
        2: 'Overseas workshops, long-term work leave'
      }
    },
    footer: {
      cookapps: 'CookApps Inc.',
      address: '8th Floor, USpace Bldg, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Korea',
      ceo: 'CEO Andrew Park',
      corp_num: 'B.N.: 220-87-92691',
      privacy_policy: 'Privacy Policy'
    }
  },
  story: {
    subtitle: 'Introducing the stories of CookApps',
    subtitle2: 'people who are challenging, learning, and growing.'
  },
  article: {
    list: 'List',
    cannot_access: 'Access Denied',
    cannot_access_detail: 'This is a non-public or no post.',
    go_recruit: 'Go to Recruit',
  },
  recruit: {
    subtitle1: 'We find a colleague who will create a pleasant',
    subtitle2: 'experience together in the daily competitive world.',
    area: 'Recruitment Area',
    apply: 'Apply',
    cancel: 'Cancel',
    applying: 'Uploading...',
    apply_success: 'Applying is Finished',
    apply_success_title: 'Applying Finished',
    apply_fail: 'An error has occurred. Please try again.<br>Please contact the administrator when problems occur continuously. (recruit@cookapps.com)',
    apply_upload_fail: 'There was a problem uploading the file. please try again.<br>Please contact the administrator when problems occur continuously. (recruit@cookapps.com)',
    apply_no_file: 'No attachments found.',
    apply_fail_title: 'Applying Failed',
    upload_fail_title: 'Upload Failed',
    upload_success: 'Assignment is uploaded',
    upload_success_title: 'Upload is Finished',
    qna: {
      title: 'Q & A',
      q1: {
        question: 'What documents are required to apply for a job?',
        answer: 'The basic documents to be submitted are resumes (self-introduction) and career descriptions (only for careers), and if necessary, submit a portfolio. Please check the required documents in each notice!'
      },
      q2: {
        question: 'Is there a resume form? What can I use for the content?',
        answer: 'You can fill it out in a free form and describe it based on experience related to the job you are applying for. Please do not include unnecessary personal information (resident ID, physical condition, family information, property information, etc.) on your resume, and it is difficult to check Korean (hwp) files, so please submit them in Word, PPT, PDF files.'
      },
      q3: {
        question: 'Is there an assignment review?',
        answer: 'The details such as whether and when the project evaluation is conducted, and the deadline for application are different for each job category. You can check the progress of the selection process in the application procedure guide in each announcement, and the details of the assignment will be guided individually.'
      },
      q4: {
        question: 'Is there any guidance on the results of the application?',
        answer: 'Yes, all the results of each step will be guided by e-mail or wired regardless of pass / fail. Results will be announced for each stage of selection without exceeding 1 to 2 weeks. If any further results are delayed, please contact recruit@cookapps.com to confirm and guide you.'
      },
      q5: {
        question: 'I am curious how long it will take from submitting an application to final acceptance.',
        answer: 'It may vary by job group / admission procedure and may change depending on the internal situation, but it usually takes less than 3 ~ 5 weeks.'
      },
      q6: {
        question: 'Can I apply to multiple fields at the same time when applying?',
        answer: 'There are no restrictions or penalties for simultaneous application, and the results of the selection are individually guided by each application.'
      },
      q7: {
        question: 'Is it possible to apply for special military service?',
        answer: 'Specialized research personnel can apply for special military service. This is for graduates of the master\'s degree in science and engineering, and can apply for job postings in the job postings that indicate that they can apply for special military service.'
      },
      q8: {
        question: 'Is it possible to apply as an industrial technical agent?',
        answer: 'Cook Abs is not a designated industrial service personnel, so it is not possible to apply in this form.'
      },
      q9: {
        question: 'I would like to delete documents and application history, such as resumes / experiences submitted during job application.',
        answer: 'The submitted documents and application history are stored for up to one year after confirmation of recruitment, and since they are destroyed without delay, please inquire individually at recruit@cookapps.com if you wish to destroy immediately.'
      },
      q10: {
        question: 'What should I do if I want to update my submitted application?',
        answer: 'When you apply for a job on the CookApps website, please fill in the name of the applicant (updated application) in the \'Name\' field and submit it to us. If you applied through other channels, please contact recruit@cookapps.com.'
      },
      q11: {
        question: 'How do I dress for the interview? Any preparations?',
        answer: 'Wear comfortable casual attire. Advance guidance will be provided if separate preparations are required, otherwise no preparations are required.'
      },
      q12: {
        question: 'What is the first and second interview format?',
        answer: 'In the 1st and 2nd interviews, applicants (1) and interviewers are provided in the form of one-to-many interviews.In the case of the first interview, the director of the studio that is being recruited, the executives, senior participation in the job group, the CEO in the second interview, COO, personnel team will participate.'
      },
      q13: {
        question: 'Is it possible to apply for parking when I visit the Interview (Cook Abs headquarters)?',
        answer: 'Yes, it is possible. If you tell the personnel team that you have come to the interview site by car, we will assist you with parking after the interview.'
      },
      q14: {
        question: 'I have a failed experience with previous application, can I apply again?',
        answer: 'Yes, of course. However, please be aware that it may be difficult to determine other results if the date has not passed since the previous failure or if there has been no change (career or portfolio) in the meantime.'
      },
      q15: {
        question: 'How are talent pools used?',
        answer: 'It is limited to the case where you agree to register for a talent pool when applying for a job on the homepage. If it is determined that you are a suitable talent when opening a new job position, the HR team will contact you individually.'
      },
      q16: {
        question: 'What is the process of joining after the final pass?',
        answer: 'After the final pass, we will guide you through the wireline and confirm your intention to work and coordinate your final date of employment. Afterwards, we will provide you with individual guidance on the final confirmed date of employment, treatment, information on CookApps, and documents you need to prepare before joining. .'
      },
      q17: {
        question: 'For long-distance interviewers, can I have a video interview or a 1st and 2nd interview in one day?',
        answer: 'If possible, we are conducting face-to-face interviews, but if you have a reason to watch video interviews or 1st and 2nd interviews at once, please contact recruit@cookapps.com and we will guide you individually.'
      }
    },
    form: {
      name: 'Name',
      job: 'Job',
      career: 'Have career',
      phone: 'Phone',
      email: 'E-mail',
      applyRoute: 'Incoming Route',
      portfolio: 'URL',
      file: 'File Upload',
      birthday: 'Birthday',
      address: 'Address',
      sex: 'Sex',
      armyStatus: 'Army Status',
      file_desc: 'Drop file here or <em>click to upload</em>',
      file_desc2: 'You can upload multiple files.',
      file_desc3: '* Required Documents: Resume, Career description, and portfolio.',
      file_desc4: '- Career descriptions are for experienced workers only.',
      file_desc5: '- Non-development job forces are portfolio selection submissions.',
      file_desc6: 'Please upload your resume or portfolio in Word or PDF file format.',
      file_desc7: 'Unzip the zip file (sample code set, or large original file, etc.), unzip and upload only the necessary files.',
      sex_male: 'Male',
      sex_female: 'Female',
      army_done: 'Completed',
      army_skip: 'Exemption',
      army_notYet: 'Not Completed',
      army_none: 'Not Applicable'
    },
  },
  contact: {
    directions: 'Directions',
    question: 'Questions',
    cookapps: 'CookApps',
    address: '8th Floor, USpace Bldg, 660, Daewangpangyo-ro,<br> Bundang-gu, Seongnam-si, Gyeonggi-do, Korea',
    preview_transport: 'Going CookApps Preview (Public Transport)',
    preview_parking: 'Going CookApps Preview (Parking)',
    biz: 'Partnership / Marketing',
    pr: 'Press / Media',
    support: 'Customer Suppport',
    job: 'Careers'
  },
  games: {
    subtitle1: 'Introducing the CookApps games',
    subtitle2: 'that make the world enjoyable!',
    caribbean_life: {
      intro: `
        캐리비안 라이프에 오신 것을 환영합니다!<br> 
        의뢰인을 도와 멋진 집들을 꾸며주세요!<br><br>
        다양한 퍼즐 레벨을 풀어서 방을 수리하고 장식하며<br>
        흥미진진한 의뢰인의 이야기를 따라 더 많은<br>
        에피소드를 플레이하세요! 망설이지 말고<br>
        캐리비안 라이프를 즐겨주세요!<br>
      `
    },
    hawaii_life: {
      intro: `
        알로하! 꿈의 집 : 하와이 라이프로 당신을 초대합니다.<br>
        당신만의 인테리어 실력을 뽐내고, 하와이의 삶을 느껴보세요!<br><br>
        당신이 원하는 대로 만들고, 디자인하고, 장식물로 꾸며서<br>
        완벽한 드림하우스를 만들기 위해 매치 3 게임을 플레이하세요!
      `
    },
    modern_city: {
      intro: `
        자신이 원하는 대로 집을 디자인하고 꾸미는 꿈을<br>
        꾸어 본 적이 있나요? 집 꾸미기와 인테리어 디자인의 마스터가 되어보세요!<br><br>
        재미있는 매치3 퍼즐을 풀어 코인을 모아 가구,<br>
        장식 아이템 등을 선택하세요. 휴식을 취하고 즐거운 시간을 보내고<br>
        힐링을 위한 공간을 만들 수 있습니다!
      `
    },
    rogue_idle: {
      intro: `
        던전을 탐험하고, 무기와 방어구를 수집하고!<br>
        공격을 피하고 모두를 소탕하세요!<br><br>
        끝없는 전장에서 보스 몬스터와 싸우고<br>
        어둠의 통치를 끝내세요!<br>
        용감한 모험가로 가득한 이곳에서<br>
        당신은 리더가 됩니다.
      `
    },
    magic_cat_piano_tile: {
      intro: `
        귀여운 고양이, 사랑스러운 강아지 동물 타일로 멋진<br>
        피아니스트처럼 아름답게 피아노를 연주해보세요!<br><br>
        냥집사님, 댕댕이 집사님들 모두 주목!<br>
        전 세계적으로 유명한 클래식,동요, 그리고 멋진<br>
        음악들과 함께 귀여운 동물 타일을 두드리며<br>
        아름다운 멜로디를 연주해보세요!
      `
    },
    jungle_gem_blast: {
      intro: `
        정글 깊숙히 숨겨진 크리스탈 사원!<br>
        즐겁고 도전적인 퍼즐로 보석을 맞추고<br>
        폭팔시키고 클리어하여 보물을 모으세요!<br><br>
        사파이어, 루비, 토파즈, 크리스탈, 비취, 오팔<br>
        귀중한 보석이 가득한 보물상자가 기다리고 있습니다.<br>
        정글에 오신 걸 환영합니다!        
      `
    },
    random_royale: {
      intro: `
        새롭고 흥미진진한 PVP 게임! 이전에 다른 게임에서<br>
        경험한 모든 것보다 더! 강력한! 디펜스 게임.<br><br>
        어두운 숲, 거대한 사막, 카리브 해변, 얼어 붙은 땅,<br>
        매혹적인 성을 탐험하세요. 솔로 플레이 또는 PVP<br>
        스타일로! 카드를 뽑고 새로운 기술을 습득하고<br>
        덱을 무적으로 만드십시오.        
      `
    },
    wonder_merge: {
      intro: `
        귀여운 크리처 3마리가 모이면 어떻게 될까요?<br>
        Wonder Merge에 등장하는 모든 크리처는<br>
        3마리만 모이면 새로운 모습으로 변신합니다 !<br><br>
        크리처 뿐 아니라 동전,항아리,나무,꽃 등<br>
        모든 것은 3개만 모이면 새로운 모습으로<br>
        변신합니다. 눈과 어둠으로 덮힌 대지에<br>
        생명과 아름다움을 깃들여 주세요!        
      `
    },
    design_star: {
      intro: `
        당신만의 멋진 인테리어를 전세계 유저들에게 뽐내보세요!<br>
        수백가지의 다양한 색상과 텍스처를 통해 쉽게 디자인하고, 아름다운 홈 데코 스타일과 함께 당신의 창의력을 표현해보세요!<br><br>        
        디자인스타는 당신이 꿈꾸던 홈인테리어를 직접 만들어볼수 있도록 도와줍니다. <br>
        그리고 전세계 유저들이 가장 많이 좋아하는 디자인을 경험해보며, 당신만의 인테리어에 힌트를 얻어보세요!
      `
    }
  }
};
