import request from '@/utils/request';

// AB 테스트를 위한 api
export const getUserRandomNo = data => request({
  url: '/web/user/radomNumber',
  method: 'post',
  data
});

export const getJobs = query => request({
  url: '/web/job/list',
  method: 'get',
  params: query
});

export const getAllJobs = query => request({
  url: '/web/job/allJobs',
  method: 'get',
  params: query
});

export const getOpenJobs = query => request({
  url: '/web/job/openJobList',
  method: 'get',
  params: query
});

export const getOpenJobsByTypeId = (jobTypeId) => request({
  url: `/web/job/openJobListByTypeId/${jobTypeId}`,
  method: 'get',
});

export const getJobDetail = data => request({
  url: '/web/job/detail',
  method: 'post',
  data
});

export const fetchJobTypeList = query => request({
  url: '/job/type/list',
  method: 'get',
  params: query
});

export const apply = data => request({
  url: '/recruit/applicant/create',
  method: 'post',
  data
});

export const applyNew = data => request({
  url: '/recruit/applicant/create-new',
  method: 'post',
  data
});

export const tempSave = data => request({
  url: '/recruit/applicant/temp-save',
  method: 'post',
  data
});

export const tempUpdate = data => request({
  url: '/recruit/applicant/temp-update',
  method: 'post',
  data
});

export const getUploadUrl = data => request({
  url: '/recruit/file/get-signed-url',
  method: 'post',
  data
});

export const getRoute = query => request({
  url: '/recruit/apply-route/list',
  method: 'get',
  params: query
});

export const getDetailByUuid = query => request({
  url: '/recruit/applicant/detail-by-uuid',
  method: 'get',
  params: query
});

export const uploadAssignment = data => request({
  url: '/recruit/applicant/upload-assignment',
  method: 'post',
  data
});

export const createTalent = data => request({
  url: '/recruit/talent/create',
  method: 'post',
  data
});

export const getTempApplicant = query => request({
  url: '/recruit/applicant/temp-applicant',
  method: 'get',
  params: query
});

export const removeFile = data => request({
  url: '/recruit/applicant/remove-file',
  method: 'post',
  data
});

export const sendQuestion = data => request({
  url: '/recruit/applicant/send-question',
  method: 'post',
  data
});
