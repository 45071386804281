import request from '@/utils/request';

export const signup = data => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/account/create',
  method: 'post',
  data
});

export const login = data => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/account/login',
  method: 'post',
  data
});

export const initPassword = data => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/account/resetPassword',
  method: 'post',
  data
});

export const changePassword = data => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/account/changePassword',
  method: 'post',
  data
});

export const registerApplication = data => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/application/register',
  method: 'post',
  data
});

export const registerSimple = data => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/application/registerSimple',
  method: 'post',
  data
});

export const mobileSimpleApply = data => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/application/mobileSimpleApply',
  method: 'post',
  data
});

export const putSurveyResult = data => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/application/survey',
  method: 'post',
  data
});

export const getAccountInfo = query => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/account/info',
  method: 'get',
  params: query
});

export const getApplyDetail = query => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/application/detail',
  method: 'get',
  params: query
});

export const saveTemp = data => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/application/save-temp',
  method: 'post',
  data
});

export const reRegister = data => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/application/re-register',
  method: 'post',
  data
});

export const removeFile = data => request({
  baseURL: process.env.VUE_APP_BASE_API_V2,
  url: '/recruit/application/remove-file',
  method: 'post',
  data
});
