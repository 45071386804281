import Cookies from 'js-cookie';

const state = {
  token: Cookies.get('ca-applicant-token') || '',
  uid: '',
  userForm: {},
  applyListItem: {},
  jobId: 0,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_UID: (state, uid) => {
    state.uid = uid;
  },
  SET_USER_FORM: (state, userForm) => {
    state.userForm = userForm;
  },
  SET_APPLICATION_ID: (state, applicationId) => {
    state.applicationId = applicationId;
  },
  SET_JOBID: (state, jobId) => {
    state.jobId = jobId;
  }
};

const actions = {
  setUserForm({ commit }, userForm) {
    commit('SET_USER_FORM', {
      name: userForm.name,
      birthday: userForm.birthday,
      email: userForm.email,
      phone: userForm.phone,
      recruitPoolCheck: userForm.recruitPoolCheck,
    });
  },
  setToken({ commit }, token) {
    Cookies.set('ca-applicant-token', token);
    commit('SET_TOKEN', token);
  },
  setUid({ commit }, uid) {
    commit('SET_UID', uid);
  },
  setApplicationId({ commit }, applicationId) {
    commit('SET_APPLICATION_ID', applicationId);
  },
  setJobId({ commit }, jobId) {
    commit('SET_JOBID', jobId);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
