import './styles/_tailwind.scss'; // tailwind css
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './lang';
// import './registerServiceWorker'
// import './plugins/element.js'
import './plugins/naver-maps.js';
import './plugins/vueMeta';

// styles
import './styles/icon.css'; // icon css
import './styles/common.scss'; // font, reset, comon styles

import 'aos/dist/aos.css'; // aos

// new main page
import AOS from 'aos'; // aos
import VueScreen from 'vue-screen'; // media query
import VueAwesomeSwiper from 'vue-awesome-swiper'; // swiper
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper'; // swiper
import 'swiper/swiper-bundle.css'; // swiper
import VuePageTransition from 'vue-page-transition'; // page transtion
import VuePrlx from 'vue-prlx'; // parallax
import VueObserveVisibility from 'vue-observe-visibility'; // vue visibility

import VueAnalytics from 'vue-analytics'; // GA

import { initFacebookSdk } from './api/facebook';
import Modal from './plugins/modal/index.js';

Vue.use(VueAnalytics, {
  id: 'UA-139746607-1',
  router,
});
Vue.use(VueScreen, {
  mobile: 280,
  tablet: 768,
  pc: 1025,
});

// new main page
SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);
Vue.use(VuePageTransition);
Vue.use(VuePrlx);
Vue.use(VueObserveVisibility);

Vue.use(Modal);

Vue.config.productionTip = false;

initFacebookSdk();

if (!sessionStorage.getItem('accessTime')) {
  sessionStorage.setItem('accessTime', new Date());
}
if (!sessionStorage.getItem('survey')) {
  sessionStorage.setItem('survey', true);
}
if (!localStorage.getItem('survey')) {
  localStorage.setItem('survey', true);
}

new Vue({
  created() {
    AOS.init();
  },
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount('#app');
