import Container from './container';

const Modal = {
  install(Vue) {
    const ModalContainer = Vue.extend(Container);
    const container = new ModalContainer();
    container.$mount(document.body.appendChild(document.createElement('div')));

    Vue.prototype.$modal = {
      open(component, params = {}) {
        container.component = component;
        container.params = params;
        return container.open(params);
      },
      close() {
        container.close();
      },
    };
  },
};

export default Modal;
