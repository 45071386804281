<template>
  <div>
    <Header />
    <!-- App Container -->
    <vue-page-transition>
      <router-view
        :key="$route.fullPath"
        class="app__container"
        :class="{ 'subscribing' : $store.getters.subscribeHeader, 'main': $route.path === '/'}"
      />
    </vue-page-transition>
    <!-- // App Container -->
    <Footer />
    <subscribe-modal />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Footer from './components/Footer.vue';
import Header from './components/Header.vue';
import SubscribeModal from './components/SubscribeModal.vue';
// import { randomPageCreate } from '@/api/trackClick';

export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    SubscribeModal,
  },
  data() {
    return {
      lang: 'ko',
      opened: false
    };
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
  },
  async created() {
    this.lang = this.language;
    window.document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
    window.addEventListener('resize', this.onResize);
    //const result = await randomPageCreate({});
  },
  methods: {
    onResize() {
      window.document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
    },
    changeLanguage() {
      this.$i18n.locale = this.lang;
      this.$store.dispatch('app/setLanguage', this.lang);
    }
  }
};
</script>
<style lang="scss">
.app__container{
  padding-top: $headerH;
  &.main{
    padding-top: 0px;
  }
  &.subscribing{
    padding-top: 100px;
  }
}
</style>
