import Cookies from 'js-cookie';

const state = {
  language: Cookies.get('language') || 'ko',
  recruitType: 0,
  showYoutube: false,
  currentVideo: {},
  prevJobTypeId: 0,
  nextJobTypeId: 0,
  openedJobCnt: 0,
  isSimpleApply: false,
  jobTypeId: 0,
  editSimplyApply: false,
  subscribeModal: false,
  subscribeHeader: false,
};

const mutations = {
  SET_LANGUAGE: (state, language) => {
    state.language = language;
    Cookies.set('language', language);
  },
  SET_RECRUIT_TYPE: (state, recruitType) => {
    state.recruitType = recruitType;
  },
  SET_SHOW_YOUTUBE: (state, showYoutube) => {
    state.showYoutube = showYoutube;
  },
  SET_CURRENT_VIDEO: (state, currentVideo) => {
    state.currentVideo = currentVideo;
  },
  SET_PREV_JOBTYPEID: (state, prevJobTypeId) => {
    state.prevJobTypeId = prevJobTypeId;
  },
  SET_NEXT_JOBTYPEID: (state, nextJobTypeId) => {
    state.nextJobTypeId = nextJobTypeId;
  },
  SET_OPENED_JOBTYPECNT: (state, openedJobCnt) => {
    state.openedJobCnt = openedJobCnt;
  },
  SET_IS_SIMPlE_APPLY: (state, isSimpleApply) => {
    state.isSimpleApply = isSimpleApply;
  },
  SET_JOBTYPE_ID: (state, jobTypeId) => {
    state.jobTypeId = jobTypeId;
  },
  SET_EDIT_SIMPLE_APPLY: (state, editSimplyApply) => {
    state.editSimplyApply = editSimplyApply;
  },
  SET_SUBSCRIBE_MODAL: (state, subscribeModal) => {
    state.subscribeModal = subscribeModal;
  },
  SET_SUBSCRIBE_HEADER: (state, subscribeHeader) => {
    state.subscribeHeader = subscribeHeader;
  },
};

const actions = {
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language);
  },
  setRecruitType({ commit }, recruitType) {
    commit('SET_RECRUIT_TYPE', recruitType);
  },
  setShowYoutube({ commit }, showYoutube) {
    commit('SET_SHOW_YOUTUBE', showYoutube);
  },
  setCurrentVideo({ commit }, currentVideo) {
    commit('SET_CURRENT_VIDEO', currentVideo);
  },
  setPrevJobTypeId({ commit }, prevJobTypeId) {
    commit('SET_PREV_JOBTYPEID', prevJobTypeId);
  },
  setNextJobTypeId({ commit }, nextJobTypeId) {
    commit('SET_NEXT_JOBTYPEID', nextJobTypeId);
  },
  setOpenedJobCnt({ commit }, openedJobCnt) {
    commit('SET_OPENED_JOBTYPECNT', openedJobCnt);
  },
  setIsSimpleApply({ commit }, isSimpleApply) {
    commit('SET_IS_SIMPlE_APPLY', isSimpleApply);
  },
  setJobTypeId({ commit }, jobTypeId) {
    commit('SET_JOBTYPE_ID', jobTypeId);
  },
  setEditSimpleApply({ commit }, editSimplyApply) {
    commit('SET_EDIT_SIMPLE_APPLY', editSimplyApply);
  },
  setSubscribeModal({ commit }, subscribeModal) {
    commit('SET_SUBSCRIBE_MODAL', subscribeModal);
  },
  setSubscribeHeader: ({ commit }, subscribeHeader) => {
    commit('SET_SUBSCRIBE_HEADER', subscribeHeader);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
