<template>
  <footer
    ref="footer"
    v-observe-visibility="visibilityChanged"
    class="w-screen bg-gray-900 pc:py-10 pc:px-0 mobile:pt-10 mobile:px-4 mobile:pb-16 "
  >
    <div class="pc:w-[86%] pc:max-w-7xl pc:mx-auto mobile:w-full">
      <div class="flex pc:flex-row pc:pb-10 pc:border-b pc:border-gray-500 mobile:flex-col">
        <div class="pc:w-[15%] pc:border-0 tablet:pb-4 mobile:pb-6 mobile:border-b mobile:border-gray-500">
          <router-link
            to="/"
          >
            <img
              v-if="$screen.breakpoint === 'pc'"
              src="@/assets/common/logo-footer.svg"
              alt="쿡앱스 로고 이미지"
            >
            <img
              v-else-if="$screen.breakpoint === 'tablet'"
              src="@/assets/common/logo-footer_tb.svg"
              alt="쿡앱스 로고 이미지"
            >
            <img
              v-else
              src="@/assets/common/logo-footer_mb.svg"
              alt="쿡앱스 로고 이미지"
            >
          </router-link>
        </div>
        <div class="flex pc:flex-row pc:col-4 pc:justify-end pc:w-[85%] mobile:flex-col mobile:w-full">
          <div class="pc:min-w-[120px]">
            <div
              class="
                    pc:text-lg pc:leading-[1.33] pc:text-gray-50 pc:mb-3 pc:py-0
                    tablet:text-xl tablet:leading-[1.3] tablet:font-extrabold tablet:py-3 
                    mobile:text-lg mobile:font-bold mobile:leading-[1.33] mobile:text-gray-200 mobile:py-2.5
              "
              @click="firstMenuOpened = !firstMenuOpened"
            >
              Navigate
              <i
                v-if="$screen.breakpoint !== 'pc' && !firstMenuOpened"
                class="float-right xi-icon xi-plus"
              />
              <i
                v-if="$screen.breakpoint !== 'pc' && firstMenuOpened"
                class="float-right xi-icon xi-minus"
              />
            </div>
            <div 
              v-if="firstMenuOpened || $screen.breakpoint === 'pc'"
              class="pc:border-0 pc:pb-0 tablet:mt-0 mobile:border-b mobile:border-gray-500 mobile:pb-4 mobile:mt-[2px]"
            >
              <router-link
                to="/about"
                class="block mb-1 text-base text-gray-200 hover:opacity-50"
              >
                About
              </router-link>
              <router-link
                to="/culture"
                class="block mb-1 text-base text-gray-200 hover:opacity-50"
              >
                Culture
              </router-link>
              <router-link
                to="/games"
                class="block mb-1 text-base text-gray-200 hover:opacity-50"
              >
                Games
              </router-link>
              <router-link
                to="/story"
                class="block mb-1 text-base text-gray-200 hover:opacity-50"
              >
                Story
              </router-link>
              <router-link
                to="/careers"
                class="block text-base text-gray-200 hover:opacity-50"
              >
                Careers
              </router-link>
            </div>
            <div 
              v-else
              class="pc:border-0 mobile:border-b mobile:border-gray-500"
            />
          </div>
          <div class="pc:min-w-[180px] pc:ml-[66px]">
            <div
              class="
                    pc:text-lg pc:leading-[1.33] pc:text-gray-50 pc:mb-3 pc:py-0
                    tablet:text-xl tablet:leading-[1.3] tablet:font-extrabold tablet:py-3 
                    mobile:text-lg mobile:font-bold mobile:leading-[1.33] mobile:text-gray-200 mobile:py-2.5
              "
              @click="secondMenuOpened = !secondMenuOpened"
            >
              Contact Us
              <i
                v-if="$screen.breakpoint !== 'pc' && !secondMenuOpened"
                class="float-right xi-icon xi-plus"
              />
              <i
                v-if="$screen.breakpoint !== 'pc' && secondMenuOpened"
                class="float-right xi-icon xi-minus"
              />
            </div>
            <div 
              v-if="secondMenuOpened || $screen.breakpoint === 'pc'"
              class="pc:border-0 pc:pb-0 mobile:border-b tablet:mt-0 mobile:border-gray-500 mobile:pb-4 mobile:mt-[2px]"
            >
              <router-link
                to="/story/85"
                class="block mb-1 text-base text-gray-200 hover:opacity-50"
              >
                Meet Us
              </router-link>
              <div class="block mb-1 text-base text-gray-200">
                pr@cookapps.com
              </div>
              <div class="block mb-1 text-base text-gray-200">
                recruit@cookapps.com
              </div>
              <div class="block text-base text-gray-200">
                support@cookapps.com
              </div>
            </div>
            <div 
              v-else
              class="pc:border-0 mobile:border-b mobile:border-gray-500"
            />
          </div>
          <div class="pc:min-w-[260px] pc:ml-[42px]">
            <div
              class="
                    pc:text-lg pc:leading-[1.33] pc:text-gray-50 pc:mb-3 pc:py-0
                    tablet:text-xl tablet:leading-[1.3] tablet:font-extrabold tablet:py-3 
                    mobile:text-lg mobile:font-bold mobile:leading-[1.33] mobile:text-gray-200 mobile:py-2.5
              "
              @click="thirdMenuOpened = !thirdMenuOpened"
            >
              Visit Us
              <i
                v-if="$screen.breakpoint !== 'pc' && !thirdMenuOpened"
                class="float-right xi-icon xi-plus"
              />
              <i
                v-if="$screen.breakpoint !== 'pc' && thirdMenuOpened"
                class="float-right xi-icon xi-minus"
              />
            </div>
            <div 
              v-if="thirdMenuOpened || $screen.breakpoint === 'pc'"
              class="pc:border-0 pc:pb-0 tablet:mt-0 mobile:border-b mobile:border-gray-500 mobile:pb-4 mobile:mt-[2px]"
            >
              <div class="block text-base text-gray-200">
                경기 성남시 분당구 대왕판교로 660<br>
                유스페이스1 B동 8층 쿡앱스
              </div>
            </div>
            <div 
              v-else
              class="pc:border-0 mobile:border-b mobile:border-gray-500"
            />
          </div>
          <div
            v-if="$screen.breakpoint === 'pc'"
            class="min-w-[120px] ml-[24px] justify-start pt-0"
          >
            <div class="pt-0.5 text-lg font-bold leading-[1.33] text-gray-50 mb-2.5">
              Follow Us
            </div>
            <div class="h-8">
              <a
                class="inline-block mr-3 hover:opacity-50"
                target="_blank"
                href="https://www.facebook.com/cookappsstory/"
              >
                <img src="@/assets/icon-new/sns/icon-facebook.svg">
              </a>
              <a
                class="inline-block mr-3 hover:opacity-50"
                target="_blank"
                href="https://www.instagram.com/cookapps_story/?hl=ko"
              >
                <img src="@/assets/icon-new/sns/icon-instagram.svg">
              </a>
              <a
                class="inline-block mr-1.5 hover:opacity-50"
                target="_blank"
                href="https://www.youtube.com/channel/UCpOylBBGTmdTkPGJhkkl_RA"
              >
                <img src="@/assets/icon-new/sns/icon-youtube.svg">
              </a>
              <a
                class="inline-block mr-3 hover:opacity-50"
                target="_blank"
                href="https://brunch.co.kr/@cookapps"
              >
                <img src="@/assets/icon-new/sns/icon-brunch.svg">
              </a>
              <a
                class="inline-block mr-1.5 hover:opacity-50"
                target="_blank"
                href="https://medium.com/cookapps"
              >
                <img src="@/assets/icon-new/sns/icon-medium.svg">
              </a>
            </div>
          </div>
          <div
            v-else
            class="flex w-full flex-row justify-between pt-2.5"
          >
            <div 
              class="pt-0.5 text-lg font-bold leading-[1.33] text-gray-200
              "
            >
              Follow Us
            </div>
            <div class="h-8">
              <a
                class="inline-block mr-3 hover:opacity-50"
                target="_blank"
                href="https://www.facebook.com/cookappsstory/"
              >
                <img src="@/assets/icon-new/sns/icon-facebook.svg">
              </a>
              <a
                class="inline-block mr-3 hover:opacity-50"
                target="_blank"
                href="https://www.instagram.com/cookapps_story/?hl=ko"
              >
                <img src="@/assets/icon-new/sns/icon-instagram.svg">
              </a>
              <a
                class="inline-block mr-1.5 hover:opacity-50"
                target="_blank"
                href="https://www.youtube.com/channel/UCpOylBBGTmdTkPGJhkkl_RA"
              >
                <img src="@/assets/icon-new/sns/icon-youtube.svg">
              </a>
              <a
                class="inline-block mr-3 hover:opacity-50"
                target="_blank"
                href="https://brunch.co.kr/@cookapps"
              >
                <img src="@/assets/icon-new/sns/icon-brunch.svg">
              </a>
              <a
                class="inline-block mr-1.5 hover:opacity-50"
                target="_blank"
                href="https://medium.com/cookapps"
              >
                <img src="@/assets/icon-new/sns/icon-medium.svg">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div 
        class="flex text-sm leading-[1.29] text-gray-300
              pc:flex-row pc:mt-4
              tablet:mt-7
              mobile:flex-col mobile:mt-6
        "
      >
        <div class="pc:w-1/2">
          © All rights reserved to Cookapps 2022
        </div>
        <div class="pc:w-1/2 pc:text-right pc:mt-0 mobile:mt-2">
          <router-link
            to="/tos-n-pp"
            class="inline-block"
          >
            이용약관
          </router-link>
          <router-link
            to="/tos-n-pp?start=1"
            class="inline-block ml-6"
          >
            개인정보 처리방침
          </router-link>
        </div>
      </div>
    </div>

    <!-- <FloatingBanner
      ref="floatingBanner"
      :class="{ fixed: bannerFixed }"
    /> -->
  </footer>
</template>

<script>
// import FloatingBanner from './FloatingBanner.vue';

export default {
  // components: {
  //   FloatingBanner,
  // },
  data() {
    return {
      bannerFixed: false,
      firstMenuOpened: false,
      secondMenuOpened: false,
      thirdMenuOpened: false,
    };
  },
  methods: {
    visibilityChanged(isVisible) {
      this.bannerFixed = isVisible ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
// footer {
//   position: relative;
//   width: 100%;
//   color: #fff;
//   box-sizing: border-box;
//   padding-top: 85px !important;
//   padding-bottom: 50px !important;
//   background-color: $neutralColor;
// }
// .footer__container {
//   max-width: $containerWidth;
//   width: 86%;
//   margin: 0 auto;
// }
// .footer__top {
//   display: flex;
//   padding-bottom: 36px;
//   border-bottom: 1px solid #777;
// }
// .footer__logo {
//   width: 20%;
//   img {
//     width: 100%;
//     max-width: 224px;
//   }
// }
// .footer__menu {
//   width: 80%;
//   display: flex;
//   &--list {
//     padding-left: 0.57rem;
//     margin-left: 2rem;
//     min-width: 120px;
//   }
//   &--tit {
//     font-family: $fontfamily;
//     font-size: 18px;
//     font-weight: 800;
//     margin-bottom: 12px;
//     color: #f5f5f5;
//   }
//   &--sub {
//     font-size: 16px;
//     color: #e1e1e1;
//   }
// }
// .footer__bottom {
//   font-size: 12px;
//   color: #bebebe;
// }

// :is(.mobile, .tablet) {
//   .footer {
//     padding: rem(120) 0px rem(140) !important;
//   }
//   .footer__container {
//     width: 100%;
//     padding: 0 1rem;
//   }
//   .footer__logo {
//     display: block;
//     width: 100%;
//     margin-bottom: rem(30);
//     img {
//       width: 8.125rem;
//       max-width: unset;
//     }
//   }
//   .footer__top {
//     display: block;
//     padding-bottom: 0;
//     border-bottom: none;
//   }
//   .footer__menu {
//     width: 100%;
//     display: block;
//   }
//   .footer__menu--list {
//     width: 100%;
//     border-top: rem(2) solid #7a7a7a;
//     padding: 0;
//     margin: rem(20) 0 0 0;
//   }
//   .footer__menu--list:not(.sns) {
//     cursor: pointer;
//   }
//   .footer__menu--list.sns {
//     overflow: hidden;
//     .footer__menu--tit {
//       float: left;
//     }
//     .footer__menu--sub {
//       margin-top: 0.6rem;
//       float: right;
//     }
//   }
//   .footer__menu--tit {
//     font-size: rem(42);
//     padding-top: rem(20);
//     margin-bottom: 0;
//     opacity: 0.7;
//   }
//   .footer__menu--sub {
//     opacity: 0.7;
//     font-size: 0.94rem;
//     margin: 0.4rem 0;
//   }
//   .xi-plus,
//   .xi-minus {
//     float: right;
//     position: relative;
//     bottom: 0.4rem;
//     padding: rem(22);
//     line-height: 1;
//     font-weight: bold;
//     font-size: 0.9rem;
//     opacity: 0.7;
//   }
//   .footer__bottom {
//     opacity: 0.5;
//     padding-top: rem(50);
//     display: block !important;
//     & > div {
//       width: 100% !important;
//       text-align: left !important;
//     }
//   }
//   .sns__item {
//     opacity: 0.7;
//     width: 2rem;
//     height: 2rem;
//   }
// }
</style>
