<template>
  <div class="confirmModal">
    <div
      class="confirm__modal__back"
      :style="$screen.breakpoint !== 'mobile' ? {left: 'calc(50% - 240px)', width: '480px', height: '228px', top : 'calc(50% - 114px)'} : {width: '100%', top : 'calc(50% - 114px)'}"
    >
      <div
        class="py-8 modal"
        :class="$screen.breakpoint === 'pc' ? 'px-8' : $screen.breakpoint === 'mobile' ? 'px-6 m-8' : 'px-6'"
        :style="{'border-radius': $screen.breakpoint === 'pc' ? '8px 8px 8px 8px' : '8px 8px 8px 8px'}"
      >
        <div class="text-center">
          <span class="headline">
            쿡앱스 브랜드 인식 조사
          </span>
        </div>
        <div 
          class="text-center body-text" 
          :style="$screen.breakpoint === 'mobile' ? 'word-break: keep-all;' : ''"
        >
          <div>
            쿡앱스에 대한 여러분의 생각을 알려주세요.
          </div>
          <div><span class="text-style-1">20</span>초면 충분합니다!</div>
        </div>
        <div>
          <el-row
            :gutter="12"
            type="flex"
            justify="space-between"
          >
            <el-col>
              <el-button
                class="font-bold"
                style="background-color: white; width: 100%; height: 48px; font-size: 18px"
                @click.once="close"
              >
                종료
              </el-button>
            </el-col>
            <el-col>
              <div>
                <el-button
                  class="font-bold"
                  type="primary"
                  style="background-color: #ff9933; width: 100%; height: 48px; font-size: 18px"
                  @click.once="goToSurvey"
                >
                  조사참여
                </el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import surveyModal from '@/components/modal/surveyModal';

export default {
  // components: {
  //   surveyModal,
  // },
  mounted() {
    document.getElementById('app').style.overflowY = 'hidden';
    document.getElementById('app').style.height = window.innerHeight+'px';
  },
  destroyed() {
    document.getElementById('app').style.overflowY = 'auto';
    document.getElementById('app').style.height = '';
  },
  methods: {
    close(){
      sessionStorage.setItem('survey', false);
      this.$emit('close');
    },
    goToSurvey(){
      this.close();
      this.$modal.open(surveyModal);
    }
  },
};
</script>
<style >
.confirmModal {
  position: fixed;
  z-index: 1001;
  top: 0; left: 0; bottom:0;
  width: 100vw; height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
}
.confirm__modal__back{
  position: absolute;
  top: 0px;
  width: 480px;
  margin: 0 auto;
  z-index: 99999;
}

.headline {
  margin: 0 0 10px;
  font-family: Pretendard;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--grey-800);
}

.body-text {
  margin: 10px 0 20px;
  font-family: Pretendard;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  text-align: center;
  color: #666666;
}
.body-text .text-style-1 {
  color: #ff7f00;
}

</style>