import axios from 'axios';
import { Message } from 'element-ui';
// import { getAuthFromCookie } from '@/utils/cookies.js'
import store from '@/store';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
  // withCredentials: true,
  headers: {
    'CA-Origin': window.location.origin,
  },
});

service.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${store.getters['userToken']}`;
    config.headers['ca-applicant-token'] = store.getters['userToken'];
    return config;
  },
  error => Promise.reject(error.response),
);

service.interceptors.response.use(
  // config => config,
  response => {
    const res = response.data;

    if (res.code !== 20000) {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000,
      });

      return Promise.reject('error');
    } else {
      return res;
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
