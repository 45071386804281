const getters = {
  language: state => state.app.language,
  showYoutube: state => state.app.showYoutube,
  setCurrentVideo: state => state.app.setCurrentVideo,
  isLoggedIn: state => !!state.user.token,
  userToken: state => state.user.token,
  userForm: state => state.user.userForm,
  applicationId: state => state.user.applicationId,
  jobId: state => state.user.jobId,
  recruitType: state => state.app.recruitType,
  prevJobTypeId: state => state.app.prevJobTypeId,
  nextJobTypeId: state => state.app.nextJobTypeId,
  openedJobCnt: state => state.app.openedJobCnt,
  isSimpleApply: state => state.app.isSimpleApply,
  jobTypeId: state => state.app.jobTypeId,
  editSimplyApply: state => state.app.editSimplyApply,
  subscribeModal: state => state.app.subscribeModal,
  subscribeHeader: state => state.app.subscribeHeader,
};

export default getters;
