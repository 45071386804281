<template>
  <div
    id="app"
    class="app"
    :class="$screen.breakpoint"
    @contextmenu.prevent
  >
    <router-view />
    <component 
      :is="'script'" 
      type="application/ld+json" 
      v-html="jsonld"
    />
  </div>
</template>
<script>
export default {
  name: 'App',
  metaInfo: {
    // title 입력하기
    title: 'CookApps',
    // link tag 입력하기
    link: [{rel: 'canonical', href: 'www.cookapps.com'}],
    // meta tag 입력하기
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, minimal-ui, viewport-fit=cover' },
      { name: 'description', content: '글로벌 유저 4억 명, 세계를 무대로 게임을 만듭니다.' }
    ]
  },
  data () {
    return {
      jsonld: {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        'name': 'CookApps',
        'url': 'https://www.cookapps.com/',
        'sameAs': [
          'https://www.facebook.com/cookappsstory',
          'https://www.instagram.com/cookapps_story/',
          'https://www.youtube.com/channel/UCpOylBBGTmdTkPGJhkkl_RA'
        ]
      }
    };
  }
};
</script>
