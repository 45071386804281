import './animated.css';

export default {
  functional: true,
  name: 'bottomSheetContainer',
  props: ['component', 'params'],
  data() {
    return {
      show: false,
      dim: true,
      dimClickClose: true,
      data: {},
    };
  },
  render(h) {
    return h(
      'div',
      {
        class: 'modal-container',
        props: { params: 1 },
      },
      [this.createModal(h, this.data), this.createDim(h)]
    );
  },
  methods: {
    open(params) {
      this.data = params;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    createModal(h) {
      return this.show
        ? h('transition',
          {
            props: {
              name: 'fade-enter',
              appear: true,
            },
          },
          [
            h(this.component, {
              props: { params: this.params },
              on: {
                close: this.close,
              },
            }),
          ]
        )
        : null;
    },

    createDim(h) {
      return this.dim && this.show
        ? h('div', {
          class: 'modal-overlay',
          style: {
            'z-index': 999,
          },
          on: {
            click: (e) => {
              if (e.target !== e.currentTarget) return;
              this.outsideClick();
            },
          },
        })
        : null;
    },

    outsideClick() {
      if (!this.dim) return;
      if (this.dimClickClose) {
        this.close(true);
        sessionStorage.setItem('survey', false);
      }
    },
    adjust() {
      const browserHeight =
        window.innerHeight || document.documentElement.clientHeight;
      this.dialogTop = (browserHeight - this.height) / 2;
    },
  },
};
