<template>
  <div
    v-if="$store.getters.subscribeModal"
    class="subscribeModal"
  >
    <div
      class="subscribeModal__dimmed"
      @click="handleClose"
    />
    <div class="subscribeModal__wrapper">
      <div class="subscribeModal__contents">
        <h1 class="subscribeModal__title relative">
          채용 메일 구독하기
          <button
            class="subscribeModal__closeBtn"
            @click="handleClose"
          >
            <i class="el-icon-close" />
          </button>
        </h1>
        <el-form
          ref="subscribeForm"
          :model="subscribeParam"
        >
          <el-form-item
            prop="email"
            style="margin-bottom: 14px;"
          >
            <label class="label">이메일 <i class="highlight__star">*</i></label>
            <el-input
              v-model="subscribeParam.email"
              type="email"
              placeholder="이메일 주소"
            />
          </el-form-item>
          <el-form-item
            prop="name"
            style="margin-bottom: 14px;"
          >
            <label class="label">이름 <i class="highlight__star">*</i></label>
            <el-input
              v-model="subscribeParam.name"
              placeholder="홍길동"
            />
          </el-form-item>
          <el-form-item
            prop="phone"
            style="margin-bottom: 14px;"
          >
            <label class="label">연락처</label>
            <el-input
              v-model="subscribeParam.phone"
              :maxlength="11"
              type="tel"
              placeholder="- 없이 입력해 주세요."
              @keydown.enter="submitForm"
            />
          </el-form-item>
          <div class="flex justify-content-between">
            <el-form-item
              prop="agree"
              style="margin-bottom: 4px;"
            >
              <el-checkbox
                v-model="subscribeParam.agreeCheck"
              >
                개인정보 수집 및 이용 동의 <i class="highlight__star">*</i>
              </el-checkbox>
            </el-form-item>
          </div>
          <div
            v-if="subscribeParam.agreeCheck"
            class="agreeDesc overflow-y-auto"
            style="height: 91px;"
          >
            <p>
              (주)쿡앱스는 인재풀 등록 및 채용 정보 관련 정보를 전달드리기 위해 최소한의 개인정보를 수집 및 이용합니다.<br><br>

              1.개인정보의 수집 및 이용 목적<br>
              쿡앱스 인재풀 등록 및 채용 정보, 채용 이벤트 등 정기 뉴스레터 전달<br><br>

              2. 수집하는 개인정보의 항목<br>
              필수 정보: 이메일, 이름<br>
              선택 정보: 연락처<br><br>

              3. 개인정보의 보유 및 이용기간<br>
              쿡앱스 뉴스레터 구독 해지 시 즉시 파기<br>
            </p>
          </div>
          <el-form-item
            class="text-right pt-10"
            style="margin-bottom: 40px;"
          >
            <el-button
              type="primary"
              style="background-color: #ff9933; width: 140px;"
              @click="submitForm"
            >
              구독하기
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { subscribe } from '@/api/subscribe';
import '@/plugins/element.js';

export default {
  name: 'SubscribeModal',
  data() {
    return {
      subscribeParam: {
        email: '',
        name: '',
        phone: '',
        agreeCheck: false,
      }
    };
  },
  methods: {
    validateEmail() {
      let isEmailValid = false;
      const email = this.subscribeParam.email;
      const regex = /\S+@\S+\.\S+/;
      if(!regex.test(email)){
        this.$message({
          message: '이메일을 다시 입력해주세요.',
          type: 'error'
        });
        return false;
      }else{
        isEmailValid = true;
      }
      return isEmailValid;
    },
    validate() {
      if(!this.validateEmail()) {
        return false;
      }else if(!this.subscribeParam.agreeCheck){
        this.$message({
          message: '개인정보 수집 및 이용에 동의해 주세요.',
          type: 'warning'
        });
        return false;
      }
      return true;
    },
    async submitForm() {
      if(!this.validate()) {
        return false;
      }else{
        const result = await subscribe(this.subscribeParam);
        if(!result.data.success){
          alert(result.data.message);
          return false;
        }else{
          alert('구독 신청이 완료되었습니다.');
          this.$store.dispatch('app/setSubscribeModal', false);
          this.subscribeParam = {
            email: '',
            name: '',
            phone: '',
            agreeCheck: false,
          };
        }
      }
    },
    handleClose() {
      this.$store.dispatch('app/setSubscribeModal', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/apply.scss';
.subscribeModal{
  position: fixed;
  z-index: 1001;
  top: 0; left: 0; bottom:0;
  width: 100vw; height: 100vh;
  position:fixed;
  overflow-y:scroll;
  overflow-x:hidden;
  :is(.mobile) &{
    height: auto;
    min-height: 100vh;
  }
  .label{
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: -0.1px;
    color: #444;
  }
  img{
    pointer-events: none;
    user-select: none;
  }
  &__dimmed{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: -1;
  }
  &__wrapper{
    position: relative;
    z-index: 9999;
    width: 520px;
    min-height: 530px;
    max-height: 700px;
    border-radius: 8px;
    margin: 0 auto;
    top: calc( (100vh - 700px) / 2 );
    background-color: #FFF;
    :is(.mobile) &{
      max-height: unset;
      max-width: unset;
      width: 100vw;
      top: 0;
      border-radius: 0;
    }
  }
  &__contents{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 32px;
    :is(.mobile) &{
      min-height: 100vh;
      height: auto;
      overflow-y: scroll;
      padding: 24px;
    }
  }
  &__title{
    margin-bottom: 32px;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.23;
    letter-spacing: -0.2px;
    color: #333;
    :is(.mobile) &{
      margin-bottom: 16px;
      text-align: center;
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: -0.1px;
    }
    :is(.mobile) &::after{
      content: '';
      position: relative;
      top: 16px;
      display: block;
      height: 1px;
      width: calc( 100% + 48px);
      margin-left: -24px;
      border-top: 1px solid #e1e1e1;
      padding-bottom: 24px;
    }
  }
  &__closeBtn{
    position: absolute;
    top: 0; right: 0;
    :is(.mobile) &{
    }
  }
}
</style>
<style lang="scss">
.el-checkbox__label{
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.1px;
  color: #444;
}
.el-checkbox__inner{
  width: 17px;
  height: 17px;
  position: relative;
  bottom: 2px;
}
.el-checkbox__inner::after{
  left: 5px;
  width: 4px;
  height: 9px;
}
.agreeDesc{
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  p{
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: -0.05px;
    color: #666;
  }
}
</style>