export default {
  main: {
    heading: '세상을 즐겁게!',
    subheading: '게임을 정말 좋아하세요?',
    description: '누군가의 인생에 즐거운 기억으로 남을',
    description2: '게임을 만드는 팀. 쿡앱스와 함께하세요',
    join_us: '함께 하실래요?',
    life: {
      title: '쿡앱스와 함께 하면 이렇게 일할 수 있어요!',
      title2: '이런 분이 쿡앱스와 잘 어울려요!',
      title3: '쿡앱스가 더 특별한 이유',
      work1: {
        1: '타이밍 놓친 게임 제작 Stop!',
        2: '지금 시장이 원하는',
        3: '게임 제작 Start!'
      },
      work2: {
        1: '게임 제작',
        2: '전반의 의사결정은',
        3: '각 팀이 직접!'
      },
      work3: {
        1: '해외 시장 타겟의',
        2: '캐주얼 게임 제작',
        3: '경험과 노하우 Get!'
      },
      work4: {
        1: 'Founder로서 꿈도',
        2: ' \'함께\' 키울 수 있는',
        3: '분사시스템!'
      },
      fit1: {
        1: '성장 방향과 목표를',
        2: '스스로 결정할 수 있는 분'
      },
      fit2: {
        1: '유저 관점에서 게임의',
        2: '재미와 경험을 생각하는 분'
      },
      fit3: {
        1: '게임 시장, 제작 과정에 대한',
        2: '지속적인 학습과 실천하는 분'
      },
      fit4: {
        1: '완벽한 결과물보다 빠른 시도와',
        2: '발전을 통해 성장하는 분'
      },
      benefits1 : {
        1: '마음껏 실험할 자유, 게임잼!',
        2: '3개월에 1번, 3일 동안'
      },
      benefits2 : {
        1: '업무 시간에 스터디를?!',
        2: '자발적 학습조직 CoP 운영'
      },
      benefits3 : {
        1: '꿀같은 성장 지원!',
        2: '교육비, 도서 구매비 모두 지원'
      },
      benefits4 : {
        1: '점심시간 2시간, 주 35시간 근무',
        2: '효율적이고 생산적인 근무환경!'
      },
      benefits5 : {
        1: '건강하게 게임 만들어요!',
        2: '건강검진 지원·사내 마사지실 운영'
      },
      benefits6 : {
        1: '리프레쉬는 확실하게!',
        2: '전사 해외워크샵, 장기근속휴가'
      }
    },
    footer: {
      cookapps: '(주) 쿡앱스',
      address: '경기도 성남시 분당구 대왕판교로 660 유스페이스 1B동 8층',
      ceo: '대표이사 박성민',
      corp_num: '사업자번호: 220-87-92691',
      privacy_policy: '개인정보처리방침'
    }
  },
  story: {
    subtitle: '도전하고, 배우고, 성장하는',
    subtitle2: '쿡앱스 사람들의 이야기를 소개합니다.'
  },
  article: {
    list: '목록',
    cannot_access: '접근불가',
    cannot_access_detail: '공개되지않은 게시글이거나 없는 게시글입니다.',
    go_recruit: '채용중인 직무 보러가기'
  },
  recruit: {
    subtitle1: '전 세계 유저의 일상에 즐거운 경험을',
    subtitle2: '함께 만들어 나갈 동료를 찾습니다.',
    area: '채용 분야',
    apply: '지원하기',
    cancel: '취소',
    applying: '지원서를 업로드중입니다.',
    apply_success: '지원이 완료되었습니다.',
    apply_success_title: '지원 완료',
    apply_fail: '에러가 발생하였습니다 다시 시도해주세요.<br>지속적으로 문제 발생시 관리자에게 문의해주세요. (recruit@cookapps.com)',
    apply_upload_fail: '파일업로드에 문제가 발생했습니다. 다시 시도해주세요.<br>지속적으로 문제 발생시 관리자에게 문의해주세요. (recruit@cookapps.com)',
    apply_no_file: '첨부파일이 없습니다.',
    apply_fail_title: '지원 실패',
    upload_fail_title: '업로드 실패',
    upload_success: '과제 업로드가 완료되었습니다.',
    upload_success_title: '업로드 완료',
    pool: {
      title: '원하시는 채용이 열려있지 않으신가요?',
      title2: '인재풀에 등록하여 안내를 받으세요!',
      apply: '인재풀 등록'
    },
    qna: {
      title: 'Q & A (자주 묻는 질문)',
      q1: {
        question: '입사 지원 시 필요한 서류는 무엇인가요?',
        answer: '기본 제출서류는 이력서(자기소개서)와 경력기술서(경력직에 한함)이며 필요 직군의 경우 포트폴리오를 제출해주시면 됩니다. 각 공고에서 필수 제출서류를 꼭 확인해주세요!'
      },
      q2: {
        question: '이력서 양식이 정해져 있나요? 내용은 어떤걸 쓰면 되나요?',
        answer: '자유양식으로 작성하시면 되며, 지원하고자 하는 직무와 유관한 경험 위주로 기술해주시면 됩니다. 이력서에 불필요한 개인정보(주민번호,신체조건,가족정보,재산정보 등)는 기재하지 말아주시고 한글(hwp)파일은 확인이 어려우니 가급적 워드,PPT,PDF파일로 제출 부탁드립니다.'
      },
      q3: {
        question: '과제심사가 있나요?',
        answer: '과제심사 진행여부와 시기, 응시 기한 등 세부사항은 직군마다 상이하며 주로 클라이언트 개발, 아트, 기획, 데이터분석 직군 채용 시 진행됩니다. 과제 전형 진행여부는 각 공고내의 전형절차 안내에서 확인하실 수 있으며 과제에 대한 세부 사항은 개별적으로 안내됩니다.'
      },
      q4: {
        question: '전형 결과 안내가 모두 진행되나요?',
        answer: '네, 합격/불합격 여부를 떠나 단계별 전형결과는 모두 이메일이나 유선으로 안내됩니다. 결과 발표는 전형 단계별로 최대 1~2주를 넘기지 않고 안내 드리고 있으며 혹시 그 이상 결과 발표가 지연될 경우 recruit@cookapps.com으로 문의주시면 확인 후 안내해 드리겠습니다.'
      },
      q5: {
        question: '지원서 제출부터 최종 합격 안내까지 소요되는 기간이 궁금합니다.',
        answer: '직군별/전형절차별로 상이할 수 있고 내부 상황에 따라 변동 가능성이 있으나 일반적으로 3~5주 이내의 시간이 소요됩니다.'
      },
      q6: {
        question: '지원 시 여러 분야에 동시에 지원해도 되나요?',
        answer: '동시 지원에 대한 제한이나 불이익은 없으며 전형 결과는 지원하신 부문별로 개별적으로 안내됩니다.'
      },
      q7: {
        question: '병역 특례 지원이 가능한가요?',
        answer: '전문연구요원으로 병역특례 지원이 가능합니다. 이공계 석사학위 졸업자가 대상이며, 채용공고에 병역특례 지원가능이라고 되어 있는 직군으로 지원하실 수 있습니다.'
      },
      q8: {
        question: '산업기능요원으로 지원이 가능한가요?',
        answer: '쿡앱스는 산업기능요원 병역 지정업체가 아니므로 해당 형태로는 지원이 불가능합니다.'
      },
      q9: {
        question: '입사지원시 제출한 이력서/경력기술서 등의 서류와 지원 이력을 삭제하고 싶습니다.',
        answer: '제출하신 서류와 지원 이력은 채용여부 확정 후 최대 1년까지 보관되며 이후 지체없이 파기하고 있으나 바로 파기하시길 원하시는 경우 recruit@cookapps.com으로 개별 문의 부탁드립니다.'
      },
      q10: {
        question: '제출한 지원서를 업데이트 하고 싶을 때는 어떻게 해야 하나요?',
        answer: '쿡앱스 홈페이지에서 입사지원시 ‘이름’란에 ‘(지원서 업데이트)지원자분 성함’을 기재하셔서 접수해주시면 보완해주신 서류로 심사를 진행하도록 하겠습니다. 다른 채널로 지원하신 경우 recruit@cookapps.com으로 문의 부탁드립니다.'
      },
      q11: {
        question: '면접 복장은 어떻게 하나요? 준비물은 없나요?',
        answer: '편안한 캐주얼 복장을 입고 오시면 됩니다. 별도 준비물이 필요할 경우 사전 안내가 진행되며, 그렇지 않을 경우 준비물은 필요하지 않습니다.'
      },
      q12: {
        question: '1,2차 면접은 어떤 형태로 진행이 되나요?',
        answer: '1,2차 모두 지원자(1),면접관(多)의 일대다 면접의 형태로 진행되며 1차 실무진 면접의 경우 채용을 진행중인 해당 스튜디오의 디렉터,실무진,직군 시니어 참여, 2차 면접의 경우 CEO,COO,인사팀이 참여하여 진행됩니다.'
      },
      q13: {
        question: '자차로 면접장(쿡앱스 본사)방문 시 주차 지원이 가능한가요?',
        answer: '네, 가능합니다. 면접 장소로 안내드릴 때 인사팀에 자차로 방문해 주셨다고 말씀해주시면 면접을 마치신 후 주차 지원을 도와드리도록 하겠습니다.'
      },
      q14: {
        question: '이전 지원 시 불합격 경험이 있는데 재지원이 가능한가요?',
        answer: '네, 물론입니다. 하지만 이전 불합격 시점으로부터 시일이 얼마 지나지 않았거나 그동안에 변동된(경력이나 포트폴리오 등)사항이 없을 경우 다른 결과로 결정되기 어려울 수 있으니 이 점 양해 부탁드립니다.'
      },
      q15: {
        question: '인재풀은 어떻게 활용되나요?',
        answer: '홈페이지 입사지원 시 인재풀 등록에 동의하신 경우에 한하며 새로운 채용 포지션 오픈 시 적합한 인재라고 판단되는 경우 인사팀에서 개별 연락을 드릴 예정입니다.'
      },
      q16: {
        question: '최종 합격 후 입사 프로세스가 어떻게 되나요?',
        answer: '최종 합격 후 유선을 통해 근무 처우를 안내 드리고 입사 의사 확인과 최종 입사일을 조율하고 있으며 이후 이메일로 최종 확정 입사일, 근무 처우, 쿡앱스 소개자료, 입사 전까지 준비하셔야 하는 서류들에 대해 개별 안내를 드리고 있습니다.'
      },
      q17: {
        question: '장거리 면접자의 경우 화상 면접이나 1,2차 면접을 하루에 볼 수 있나요?',
        answer: '가급적 대면 면접으로 진행하고 있으나 화상 면접이나 1,2차 면접을 한 번에 보셔야 하는 사유가 있으신 경우 recruit@cookapps.com으로 문의 주시면 개별 안내 드리도록 하겠습니다.'
      }
    },
    form: {
      name: '이름',
      englishName: '영문 이름',
      job: '직무',
      career: '경력직',
      careerPeriod: '경력기간',
      phone: '연락처',
      email: '이메일',
      applyRoute: '최초 공고 확인경로',
      portfolio: 'URL',
      file: '파일 업로드',
      birthday: '생년월일 예) 1990.01.01',
      password: '비밀번호',
      passwordConfirm: '비밀번호 확인',
      address: '주소',
      sex: '성별',
      armyStatus: '병역정보',
      file_desc: '파일을 드래그하거나 <em>클릭하여 업로드 해주세요</em>',
      file_desc2: '여러개의 파일을 올릴 수 있습니다.',
      file_desc3: '* 필수제출서류: 이력서, 경력기술서, 포트폴리오를 첨부해주세요.',
      file_desc4: '- 경력기술서는 경력자만 해당합니다.',
      file_desc5: '- 마케팅, 경영지원 등 비개발직군은 포트폴리오 선택 제출입니다.',
      file_desc6: '이력서나 포트폴리오는 워드, 혹은 PDF 파일 형식으로 올려주세요.',
      file_desc7: 'zip 파일은 부득이한 경우 (샘플 코드 모음, 혹은 대용량 원화 파일 등)를 제외하고 압축 해제 후 필요한 파일만 올려주세요.',
      sex_male: '남자',
      sex_female: '여자',
      handicap_none: '해당없음',
      handicap_normal: '일반',
      handicap_work: '산재',
      handicap_patriot: '보훈',
      army_done: '병역필',
      army_skip: '병역면제',
      army_notYet: '미필',
      army_proIng: '병역특례 복무중(전문)',
      army_none: '해당없음',
      edu_type: {
        0: '고등학교',
        1: '대학교',
        2: '대학원 (석사)',
        3: '대학원 (박사)'
      },
      project_type: {
        0: '사내프로젝트',
        1: '외주',
        2: '프리랜서',
        3: '기타'
      },
      certi_type: {
        0: '자격증',
        1: '어학자격증',
      },
    },
  },
  contact: {
    directions: '찾아오시는 길',
    question: '문의',
    cookapps: '쿡앱스',
    address: '경기도 성남시 분당구 대왕판교로 660<br> 유스페이스 1B동 8층',
    preview_transport: '쿡앱스 미리가보기 (대중교통편)',
    preview_parking: '쿡앱스 미리가보기 (주차편)',
    biz: '사업 / 마케팅 제휴',
    pr: '취재요청',
    support: '고객문의',
    job: '채용문의',
  },
  games: {
    subtitle1: '세상을 즐겁게 만드는',
    subtitle2: '쿡앱스의 게임을 소개합니다!',
    caribbean_life: {
      intro: `
        캐리비안 라이프에 오신 것을 환영합니다!<br> 
        의뢰인을 도와 멋진 집들을 꾸며주세요!<br><br>
        다양한 퍼즐 레벨을 풀어서 방을 수리하고 장식하며<br>
        흥미진진한 의뢰인의 이야기를 따라 더 많은<br>
        에피소드를 플레이하세요! 망설이지 말고<br>
        캐리비안 라이프를 즐겨주세요!<br>
      `
    },
    hawaii_life: {
      intro: `
        알로하! 꿈의 집 : 하와이 라이프로 당신을 초대합니다.<br>
        당신만의 인테리어 실력을 뽐내고, 하와이의 삶을 느껴보세요!<br><br>
        당신이 원하는 대로 만들고, 디자인하고, 장식물로 꾸며서<br>
        완벽한 드림하우스를 만들기 위해 매치 3 게임을 플레이하세요!
      `
    },
    modern_city: {
      intro: `
        자신이 원하는 대로 집을 디자인하고 꾸미는 꿈을<br>
        꾸어 본 적이 있나요? 집 꾸미기와 인테리어 디자인의 마스터가 되어보세요!<br><br>
        재미있는 매치3 퍼즐을 풀어 코인을 모아 가구,<br>
        장식 아이템 등을 선택하세요. 휴식을 취하고 즐거운 시간을 보내고<br>
        힐링을 위한 공간을 만들 수 있습니다!
      `
    },
    rogue_idle: {
      intro: `
        던전을 탐험하고, 무기와 방어구를 수집하고!<br>
        공격을 피하고 모두를 소탕하세요!<br><br>
        끝없는 전장에서 보스 몬스터와 싸우고<br>
        어둠의 통치를 끝내세요!<br>
        용감한 모험가로 가득한 이곳에서<br>
        당신은 리더가 됩니다.
      `
    },
    magic_cat_piano_tile: {
      intro: `
        귀여운 고양이, 사랑스러운 강아지 동물 타일로 멋진<br>
        피아니스트처럼 아름답게 피아노를 연주해보세요!<br><br>
        냥집사님, 댕댕이 집사님들 모두 주목!<br>
        전 세계적으로 유명한 클래식,동요, 그리고 멋진<br>
        음악들과 함께 귀여운 동물 타일을 두드리며<br>
        아름다운 멜로디를 연주해보세요!
      `
    },
    jungle_gem_blast: {
      intro: `
        정글 깊숙히 숨겨진 크리스탈 사원!<br>
        즐겁고 도전적인 퍼즐로 보석을 맞추고<br>
        폭팔시키고 클리어하여 보물을 모으세요!<br><br>
        사파이어, 루비, 토파즈, 크리스탈, 비취, 오팔<br>
        귀중한 보석이 가득한 보물상자가 기다리고 있습니다.<br>
        정글에 오신 걸 환영합니다!        
      `
    },
    random_royale: {
      intro: `
        새롭고 흥미진진한 PVP 게임! 이전에 다른 게임에서<br>
        경험한 모든 것보다 더! 강력한! 디펜스 게임.<br><br>
        어두운 숲, 거대한 사막, 카리브 해변, 얼어 붙은 땅,<br>
        매혹적인 성을 탐험하세요. 솔로 플레이 또는 PVP<br>
        스타일로! 카드를 뽑고 새로운 기술을 습득하고<br>
        덱을 무적으로 만드십시오.        
      `
    },
    wonder_merge: {
      intro: `
        귀여운 크리처 3마리가 모이면 어떻게 될까요?<br>
        Wonder Merge에 등장하는 모든 크리처는<br>
        3마리만 모이면 새로운 모습으로 변신합니다 !<br><br>
        크리처 뿐 아니라 동전,항아리,나무,꽃 등<br>
        모든 것은 3개만 모이면 새로운 모습으로<br>
        변신합니다. 눈과 어둠으로 덮힌 대지에<br>
        생명과 아름다움을 깃들여 주세요!        
      `
    }
  }
};
